export const userDetailsCookie="userDetails"
export const adminRole= "admin";
export const salesAgentRole= "sales_agent";
// export const ManagerRole= "manager";

export const googleMapsApiKey="AIzaSyCVw4fDdxUEPr0Z6ZbSAJD_MJzqZ_eAbQo";
export const projected_roi_value= 18.29;
export const netZero_value=43.3;
export const payback_value=8.1;
export const carbonAvoided_value=6175;
export const return_on_investment_value=13.75;
export const money_saved_value=5470.5;
export const forecastVactual_value=97;

export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];


 export const dataLayerOptions = {
    none: 'No layer',
    mask: 'Roof mask',
    dsm: 'Digital Surface Model',
    rgb: 'Aerial image',
    annualFlux: 'Annual sunshine',
    monthlyFlux: 'Monthly sunshine',
    hourlyShade: 'Hourly shade',
  };



export const placeTypeList=[
  { "id": 7, "type": "bakery" },
  { "id": 8, "type": "bank" },
  { "id": 10, "type": "beauty_salon" },
  { "id": 12, "type": "book_store" },
  { "id": 17, "type": "car_dealer" },
  { "id": 18, "type": "car_rental" },
  { "id": 19, "type": "car_repair" },
  { "id": 20, "type": "car_wash" },
  { "id": 25, "type": "clothing_store" },
  { "id": 26, "type": "convenience_store" },
  { "id": 28, "type": "dentist" },
  { "id": 29, "type": "department_store" },
  { "id": 30, "type": "doctor" },
  { "id": 33, "type": "electronics_store" },
  { "id": 36, "type": "florist" },
  { "id": 37, "type": "funeral_home" },
  { "id": 38, "type": "furniture_store" },
  { "id": 40, "type": "gym" },
  { "id": 42, "type": "hardware_store" },
  { "id": 45, "type": "hospital" },
  { "id": 54, "type": "locksmith" },
  { "id": 55, "type": "lodging" },
  { "id": 57, "type": "meal_takeaway" },
  { "id": 61, "type": "moving_company" },
  { "id": 64, "type": "painter" },
  { "id": 67, "type": "pet_store" },
  { "id": 68, "type": "pharmacy" },
  { "id": 69, "type": "physiotherapist" },
  { "id": 70, "type": "plumber" },
  { "id": 74, "type": "real_estate_agency" },
  { "id": 75, "type": "restaurant" },
  { "id": 76, "type": "roofing_contractor" },
  { "id": 80, "type": "shoe_store" },
  { "id": 81, "type": "shopping_mall" },
  { "id": 82, "type": "spa" },
  { "id": 84, "type": "storage" },
  { "id": 85, "type": "store" },
  { "id": 93, "type": "travel_agency" },
  { "id": 95, "type": "veterinary_care" }
]
 