import React, { Fragment } from "react";
import Loader from "../layout/loader";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation  } from "react-router-dom";

const App = () => {
  const location = useLocation();

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          {location.pathname !== "/map" && <Sidebar />}
          <div
            className={
              location.pathname !== "/map" ? `page-body` : "page-body-map"
            }
          >
            <Outlet />
            {/* hello */}
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default App;
